export default [
  {
    name: "Lukas Ng",
    position: "COO | Co-Founder",
    img: require("../assets/images/Lukas Square.jpg"),
    linkedIn: "https://www.linkedin.com/in/lukas-ng-567a0b146/",
    email: "lukas@ezsofe.com",
    details:
      "This is details of Lukas Ng Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "fulltime"
  },
  {
    name: "Aow Jia Rong",
    position: "CEO | Co-Founder",
    img: require("../assets/images/aow_jiarong.jpg"),
    linkedIn: "https://www.linkedin.com/in/aowjiarong/",
    email: "jiarong@ezsofe.com",
    details:
      "This is details of Aow Jia Rong Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "fulltime"
  },
  {
    name: "Alena Lim",
    position: "Admin Director | Co-Founder",
    img: require("../assets/images/AlenaSquare.jpeg"),
    linkedIn: "https://www.linkedin.com/in/alena-lim-02a7a9101/",
    email: "alena@ezsofe.com",
    details:
      "This is details of Alena Lim Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "fulltime"
  },
  {
    name: "Erwin Lee",
    img: require("../assets/images/Erwin.jpg"),
    position: "Software Developer",
    linkedIn: "https://www.linkedin.com/in/erwin-lee/",
    email: "",
    details:
      "This is details of Mark Paulo Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "fulltime"
  },
  {
    name: "Dzulhilmi Dzulkifli",
    img: require("../assets/images/Dzulhilmi.jpg"),
    position: "Partner",
    linkedIn: "https://www.linkedin.com/in/erwin-lee/",
    email: "",
    details:
      "This is details of Mark Paulo Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "fulltime"
  },
  {
    name: 'Sebastian Sim',
    position: 'Associate Developer',
    img: require('../assets/images/SebastianSim.png'),
    linkedIn: 'https://www.linkedin.com/in/sebastian-sim-51486ba0/',
    email: '',
    details: 'This is details of Dzulhilmi Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
    type: "associate_dev"
  },
  {
    name: "Xavier Chua",
    img: require("../assets/images/Xavier.jpg"),
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/xavier06202",
    email: "",
    details:
      "This is details of Xavier ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Lincoln Lim",
    img: require("../assets/images/Lincoln.jpg"),
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/limlincoln98",
    email: "",
    details:
      "This is details of Lincoln Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Mark Paulo",
    img: require("../assets/images/MarkPaulo.jpg"),
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/mark-paulo-franco-1909b817b/",
    email: "",
    details:
      "This is details of Mark Paulo Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Nicole Yu",
    img: require("../assets/images/Nicole.png"),
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/nicole-yu/",
    email: "",
    details:
      "This is details of Nicole Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Low Yee Lei",
    img: require("../assets/images/Yee Lei.png"),
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/low-yee-lei-8573a6160/",
    email: "",
    details:
      "This is details of Yee Lei Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Han Jiyao",
    img: "https://ui-avatars.com/api/?name=Han%20Jiyao&size=200",
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/jiyao-han-a596b7157/",
    email: "",
    details:
      "This is details of Yee Lei Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Joanne Goh",
    img: require("../assets/images/Joanne.png"),
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/joanne-ng-coder/",
    email: "",
    details:
      "This is details of Joanne Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Sabrinah Foat",
    img: require("../assets/images/Sabrinah.jpg"),
    position: "Student Developer (Intern)",
    linkedIn: "https://www.linkedin.com/in/sabrinah-foat-46a3b8155/",
    email: "nur.sabrinah.foat@ezsofe.com",
    details:
      "This is details of Joanne Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Cheo Cheang Ming",
    img: require("../assets/images/Cheang Ming.png"),
    position: "Student Developer (Intern)",
    linkedIn: "https://www.linkedin.com/in/cheo-cheang-ming-616375153/",
    email: "cheo.cheang.ming@ezsofe.com",
    details:
      "This is details of Joanne Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Camillus Yeo",
    img: require("../assets/images/Camilius.png"),
    position: "Student Developer (Intern)",
    linkedIn: "https://www.linkedin.com/in/camillus-yeo-a3b4b0154/",
    email: "yeo.camillus@ezsofe.com",
    details:
      "This is details of Joanne Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Or Ling Yun",
    img: require("../assets/images/Lingyun.png"),
    position: "Student Developer (Intern)",
    linkedIn: "https://www.linkedin.com/in/ling-yun-or-a85840152/",
    email: "or.ling.yun@ezsofe.com",
    details:
      "This is details of Joanne Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Yong Kai Qi",
    img: require("../assets/images/Kai Qi.png"),
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/yongkaiqi/",
    email: "",
    details:
      "This is details of Joanne Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Tan Pei Si",
    img: require("../assets/images/Peisi.png"),
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/tan-pei-si-79a218150/",
    email: "",
    details:
      "This is details of Joanne Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Marcus Ryan Low",
    img: require("../assets/images/MarcusRyanLow.png"),
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/marcus-ryan-low-675a1814a/",
    email: "",
    details:
      "This is details of Joanne Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "James Tsui",
    img: require("../assets/images/JamesTsui.png"),
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/james-tsui-45ab08120/",
    email: "",
    details:
      "This is details of Joanne Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Jonathan Lee",
    img: require("../assets/images/Jonathan.jpeg"),
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/jonathan-lee-905152174/",
    email: "",
    details:
      "This is details of Joanne Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  },
  {
    name: "Zheng Xinxin",
    img: require("../assets/images/Cindy.jpg"),
    position: "Student Developer",
    linkedIn: "https://www.linkedin.com/in/zxx/",
    email: "",
    details:
      "This is details of Joanne Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.",
    type: "intern"
  }
];
